import { ChartElement } from '../../chart/element';
import { D3Chart } from '../../chart';
import * as d3 from 'd3';
import { maybeMap, toMutable } from 'core';

import './styles.scss';
import { TransitionConfig } from '../core';

const DEFAULT_DURATION = 3000,
  DEFAULT_DELAY = 0;
export interface DataPoint {
  readonly date: Date;
  readonly hoverValue: number;
  readonly total: number;
  readonly formattedValue?: string;
  readonly additionalData?: {
    readonly indexScore?: number;
  };
}

export interface Props {
  readonly translateX: number;
  readonly translateY: number;
  readonly data: readonly DataPoint[];
  readonly x: d3.ScaleTime<number, number>;
  readonly y: d3.ScaleLinear<number, number>;
  readonly transition?: TransitionConfig;
  readonly lineColor?: string;
}

export interface ChartSelections {
  readonly path: d3.Selection<
    SVGPathElement,
    // eslint-disable-next-line functional/prefer-readonly-type
    DataPoint[],
    null,
    undefined
  >;
}

export class Line extends ChartElement<Props, ChartSelections> {
  draw(chart: D3Chart, props: Props) {
    const { lineColor, translateX, translateY, x, y } = props;
    const data = toMutable(props.data);

    const g = chart
      .append('g')
      .attr('transform', 'translate(' + translateX + ',' + translateY + ')');

    const valueline = d3
      .line<DataPoint>()
      .x((d) => x(d.date))
      .y((d) => y(d.total));

    const path = g
      .append('path')
      .datum(data)
      .attr('class', 'line')
      .attr('fill', 'none')
      .attr('stroke', lineColor ? lineColor : 'hsl(78, 50%, 57%)')
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr('stroke-width', 4)
      .attr('d', valueline);

    //  Get the numerical length of the line (not width) for transition
    const totalLength = maybeMap(path.node(), getTotalLength) ?? 0;

    path
      .attr('stroke-dasharray', totalLength + ' ' + totalLength)
      .attr('stroke-dashoffset', totalLength)
      .transition()
      .delay(props.transition ? props.transition.delay : DEFAULT_DELAY)
      .duration(props.transition ? props.transition.duration : DEFAULT_DURATION)
      .ease(d3.easeLinear)
      .attr('stroke-dashoffset', 0);

    return { path };
  }

  update(_chart: D3Chart, props: Props, selections: ChartSelections) {
    const { x, y, data } = props;

    selections.path.datum(data);

    const valueline = d3
      .line<DataPoint>()
      .x((d) => x(d.date))
      .y((d) => y(d.total));

    selections.path.attr('d', valueline);

    const totalLength = maybeMap(selections.path.node(), getTotalLength) ?? 0;

    selections.path
      .attr('stroke-dasharray', totalLength + ' ' + totalLength)
      .attr('stroke-dashoffset', 0);
  }

  clear(chart: D3Chart) {
    // Remove event listeners
    chart.selectAll('.line').on('click', null);
  }
}

function getTotalLength(node: SVGPathElement | null) {
  try {
    // this throws sometimes in IE11
    return node?.getTotalLength() ?? 0;
  } catch {}
  return 0;
}
