import React from 'react';
import {
  AnalyticsDomain,
  AnalyticsSubdomain,
  BenchmarkFilter,
  FipsAreaWithShortName,
  MapExtentBounds,
  SafeRecordDictionary,
  TrackVariable,
  VariableDisplayType,
} from 'core';
import {
  CommunityStatisticsDomainOption,
  DataSourceItem,
  FilterSort,
  SortByItem,
  sortOptions,
} from '../filter-sort-container';
import {
  analyticsBenchmarkNumericValues,
  getFilteredBenchmarkValue,
} from 'client/shared/core/performance-data';
import _ from 'lodash';
import { CreateSavedVisualizationInputWithMultipleFips } from 'client/shared/core/saved-visualization';
import { CommunityStatisticsCard } from 'client/shared/components/domain-community-statistics-card';
import { DOMAIN_CONTAINER_COPY } from 'client/admin/track/pages/domain/copy';
import { MultiPolygon } from '@turf/helpers';
import { GroupIndicatorCard } from 'client/shared/components/group-indicator-card';

interface CommunityStatisticsProps {
  readonly trackVariables: readonly TrackVariable[];
  readonly currentFips: FipsAreaWithShortName;
  readonly domain: AnalyticsDomain;
  readonly benchmarkFilter: BenchmarkFilter;
  readonly displayType: VariableDisplayType;
  readonly canNotManageDataPoints?: boolean;
  readonly saveVisualization: (
    clientInput: CreateSavedVisualizationInputWithMultipleFips
  ) => Promise<void>;
  readonly comparisonGroupId?: string;
  readonly disableSaveVisualization?: boolean;
  readonly fipsShapeByFipsCode: SafeRecordDictionary<string, MultiPolygon>;
  readonly groupDoesNotContainPublisher: boolean;
  readonly mapBoundingCoordinates?: MapExtentBounds;
}

export enum CommunityStatisticVisualizationType {
  MAP = 'MAP',
  LINE = 'LINE',
}

// The maximum number of maps to display on a page at one 10
// Too high a number will cause previously-rendered maps to turn off due to a lack of WebGL contexts
const MAX_DISPLAYED_MAPS = 5;

const baseClass = 'pn-community-statistics-container';

export const CommunityStatisticsContainer: React.FC<CommunityStatisticsProps> = (
  p
) => {
  const publisherDataAvailable =
    !p.groupDoesNotContainPublisher || !p.comparisonGroupId;
  const [subdomain, setSubdomain] = React.useState<AnalyticsSubdomain | null>(null);
  const [sortBy, setSortBy] = React.useState<SortByItem>(
    sortOptions.find(
      (option) => publisherDataAvailable || !option.requiresPubData
    ) ?? sortOptions[0]
  );
  const [selectedDataSources, setSelectedDataSources] = React.useState<
    readonly DataSourceItem[]
  >([]);
  const variablesWithData = React.useMemo(() => {
    if (!!p.comparisonGroupId) {
      return p.trackVariables.filter((variable) => variable.areasData.length > 0);
    } else {
      return p.trackVariables.filter((variable) =>
        variable.areasData.find((ad) => ad.fipsArea.id === p.currentFips.id)
      );
    }
  }, [p.trackVariables, p.currentFips, p.comparisonGroupId]);
  const filteredVariables: readonly TrackVariable[] = React.useMemo(
    () =>
      variablesWithData.filter(
        (v) => !subdomain || v.domains.map((d) => d.subdomain).includes(subdomain)
      ),
    [variablesWithData, subdomain]
  );
  const [variableVisualizations, setVariableVisualizations] = React.useState<
    Record<CommunityStatisticVisualizationType, Set<string>>
  >({
    [CommunityStatisticVisualizationType.MAP]: new Set(),
    [CommunityStatisticVisualizationType.LINE]: new Set(),
  });
  const sortableVariables: readonly (CommunityStatisticsDomainOption &
    TrackVariable)[] = React.useMemo(
    () =>
      filteredVariables.map((v) => {
        const recentIndicator = v.areasData
          .find((ad) => ad.fipsArea.id === p.currentFips.id)
          ?.performanceData.sort(
            (a, b) => b.recordedAt.getTime() - a.recordedAt.getTime()
          )[0];

        const benchmarkValue = recentIndicator
          ? getFilteredBenchmarkValue(recentIndicator, p.benchmarkFilter)
          : null;
        const temp: CommunityStatisticsDomainOption & TrackVariable = {
          ...v,
          benchmarkNumber:
            benchmarkValue && v.direction
              ? _.get(analyticsBenchmarkNumericValues, benchmarkValue, 0)
              : 0,
          variable: v,
          label: v.label,
          recordedAt: recentIndicator?.recordedAt,
          indicators: [],
        };
        return temp;
      }),
    [filteredVariables, p.benchmarkFilter, p.currentFips]
  );

  const sortedVariables: readonly (CommunityStatisticsDomainOption &
    TrackVariable)[] = React.useMemo(
    () =>
      _.orderBy(sortableVariables, sortBy.selector, [sortBy.order]).filter(
        (v) =>
          (!selectedDataSources.length ||
            selectedDataSources.some((ds) => ds.id === v.source)) &&
          v.areasData.length
      ),
    [selectedDataSources, sortBy.order, sortBy.selector, sortableVariables]
  );

  const [expandedStateByVariableId, setExpandedStateByVariableId] = React.useState<{
    [variableId: string]: boolean;
  }>(
    sortedVariables.reduce(
      (acc, { id }) => {
        return { ...acc, [id]: false };
      },
      {} as { readonly [variableId: string]: boolean }
    )
  );

  const dataSources: readonly DataSourceItem[] = _.uniqBy(
    filteredVariables.map((v) => ({
      id: v.source ?? '',
      name: v.source ?? '',
    })),
    (source) => source.id
  );

  const handleToggle = React.useMemo(
    () => (variableId: string) => {
      setExpandedStateByVariableId((prevExpandedStatebyVariableId) => {
        return {
          ...prevExpandedStatebyVariableId,
          [variableId]: !prevExpandedStatebyVariableId[variableId],
        };
      });
    },
    []
  );
  const handleToggleVisualization = React.useCallback(
    (variableId: string, visualizationType: CommunityStatisticVisualizationType) => {
      setVariableVisualizations((prevState) => {
        if (prevState[visualizationType].has(variableId)) {
          prevState[visualizationType].delete(variableId);
        } else {
          // Only allow visualization per variable.
          // Remove variable from all visualizations, then add to current visualization
          prevState[CommunityStatisticVisualizationType.MAP].delete(variableId);
          prevState[CommunityStatisticVisualizationType.LINE].delete(variableId);
          prevState[visualizationType].add(variableId);
        }
        // Limit how many maps can be shown at once.
        // If past limit, turn off oldest map
        if (
          prevState[CommunityStatisticVisualizationType.MAP].size >
          MAX_DISPLAYED_MAPS
        ) {
          prevState[CommunityStatisticVisualizationType.MAP].delete(
            [...prevState[CommunityStatisticVisualizationType.MAP].values()][0]
          );
        }
        return { ...prevState };
      });
    },
    []
  );

  const variableVisualizationType = (varId: string) => {
    if (variableVisualizations[CommunityStatisticVisualizationType.MAP].has(varId)) {
      return CommunityStatisticVisualizationType.MAP;
    } else if (
      variableVisualizations[CommunityStatisticVisualizationType.LINE].has(varId)
    ) {
      return CommunityStatisticVisualizationType.LINE;
    } else {
      return null;
    }
  };

  return (
    <div>
      <div
        className={`${baseClass} pt-5 pb-4 d-flex flex-wrap justify-content-between`}
      >
        <h3>{DOMAIN_CONTAINER_COPY.communityStatistics.header}</h3>
      </div>
      <FilterSort
        dataSources={dataSources}
        publisherDataAvailable={publisherDataAvailable}
        selectedDataSources={selectedDataSources}
        selectedSubdomain={subdomain}
        setSelectedDataSources={setSelectedDataSources}
        setSelectedSubdomain={setSubdomain}
        setSortBy={setSortBy}
        sortBy={sortBy}
        variables={variablesWithData}
      />
      {sortedVariables.map((v) => {
        if (!!p.comparisonGroupId) {
          return (
            <GroupIndicatorCard
              benchmarkFilter={p.benchmarkFilter}
              canNotManageDataPoints={p.canNotManageDataPoints}
              className="mb-2"
              comparisonGroupId={p.comparisonGroupId}
              currentFips={p.currentFips.id}
              disableSaveVisualization={p.disableSaveVisualization}
              displayType={p.displayType}
              domain={p.domain}
              expanded={!!expandedStateByVariableId[v.id]}
              fipsShapeByFipsCode={p.fipsShapeByFipsCode}
              indicator={v}
              key={`${v.id}-card-group`}
              mapBoundingCoordinates={p.mapBoundingCoordinates}
              saveVisualization={p.saveVisualization}
              toggleExpanded={handleToggle}
              toggleVisualization={handleToggleVisualization}
              variableVisualizationType={variableVisualizationType(v.id)}
            />
          );
        } else {
          return (
            <CommunityStatisticsCard
              benchmarkFilter={p.benchmarkFilter}
              canNotManageDataPoints={p.canNotManageDataPoints}
              className="mb-3"
              comparisonGroupId={p.comparisonGroupId}
              currentFips={p.currentFips}
              disableSaveVisualization={p.disableSaveVisualization}
              displayType={p.displayType}
              domain={p.domain}
              expanded={expandedStateByVariableId[v.id]}
              key={`${v.id}-indicator-single`}
              saveVisualization={p.saveVisualization}
              toggleExpanded={handleToggle}
              variable={v}
            />
          );
        }
      })}
    </div>
  );
};
