import { useGetNivoColors } from 'client/shared/hooks/use-get-nivo-colors';
import * as React from 'react';
import './styles.scss';
import {
  AnalyticsValueType,
  DateLevel,
  FipsAreaWithShortName,
  TrackAreaData,
  TrackDatum,
  TrackVariable,
  VariableDirection,
} from 'core';
import _ from 'lodash';
import { ComparisonGroupLineChart } from '../domain-indicators-group-line-chart';
import { ComparisonGroupResultTable } from '../comparison-group-result-table';

interface Props {
  readonly label: string | null;
  readonly variable: Pick<
    TrackVariable,
    'id' | 'name' | 'valueType' | 'direction' | 'dateLevel' | 'statisticType'
  >;
  readonly fipsAreas: readonly FipsAreaWithShortName[];
  readonly trackAreaData: readonly TrackAreaData[];
  readonly showLineChart: boolean;
  readonly expanded?: boolean;
  readonly isEmbedded?: boolean;
}

export interface DomainIndicatorsFipsAreaRow {
  readonly id: string; //root Fips
  readonly rank: number;
  readonly dataAreaFips: string;
  readonly name: string;
  readonly color: string;
  readonly recentDatum: TrackDatum | null;
  readonly trackAreaData: TrackAreaData;
  readonly variableName: string;
  readonly variableValueType: AnalyticsValueType;
  readonly variableDateLevel: DateLevel;
  readonly variableDirection: VariableDirection | null;
}

export interface RankingData {
  readonly top5RanksData: readonly DomainIndicatorsFipsAreaRow[];
  readonly bottom5RanksData: readonly DomainIndicatorsFipsAreaRow[];
  readonly numberOfRankings: number;
}
const baseClass = 'pn-domain-indicators-group-wrapper';

export const DomainIndicatorsGroupWrapper: React.FC<Props> = (props) => {
  const { getColors } = useGetNivoColors();
  const dataAreas = _.uniqBy(
    props.trackAreaData.flatMap((ad) => ad.performanceData.map((pd) => pd.fipsArea)),
    (fa) => fa.id
  );
  const colors = getColors(dataAreas.length);
  const areaColors = dataAreas.reduce<Record<string, string>>((prev, curr, idx) => {
    return { ...prev, [curr.id]: colors[idx] };
  }, {});
  const entriesPerPage = _.ceil(props.trackAreaData.length / 2);

  const data = React.useMemo(
    () =>
      toSortedDomainIndicatorsFipsAreaRows(
        props.variable,
        props.fipsAreas,
        props.trackAreaData,
        areaColors
      ),
    [areaColors, props.fipsAreas, props.trackAreaData, props.variable]
  );

  const rankingData: RankingData = React.useMemo(() => {
    const lowestRank = data[data.length - 1].rank;

    return {
      numberOfRankings: lowestRank,
      top5RanksData: data.filter((d) => d.rank <= 5),
      bottom5RanksData: data.filter((d) => d.rank > lowestRank - 5),
    };
  }, [data]);

  const [fipsAreaVisibility, setFipsAreaVisibility] = React.useState<
    Record<string, boolean> //Key is the unique fips of the data points loaded for each line
  >(
    data.reduce<Record<string, boolean>>((prev, curr) => {
      // Start by only making top 5 / bottom 5 visible
      const isInitiallyVisible =
        curr.rank <= 5 || curr.rank > rankingData.numberOfRankings - 5;
      return { ...prev, [curr.dataAreaFips]: isInitiallyVisible };
    }, {})
  );

  const [hoverLine, setHoverLine] = React.useState<string | null>(null); //value is the color of the hovered line or row(s)

  const toggleShowingFipsArea = (rowId: string) => {
    setFipsAreaVisibility((prevFipsAreaVisibility) => {
      return {
        ...prevFipsAreaVisibility,
        [rowId]: !prevFipsAreaVisibility[rowId],
      };
    });
  };

  const lineChartData = React.useMemo(() => {
    return _.uniqBy(data, (row) => row.color);
  }, [data]);

  return (
    <div className={`${baseClass}`}>
      {props.label && <div className={`${baseClass}-label`}>{props.label}</div>}

      {props.showLineChart && (
        <ComparisonGroupLineChart
          data={lineChartData}
          fipsAreaVisibility={fipsAreaVisibility}
          hoverLine={hoverLine}
          setHoverLine={setHoverLine}
          variable={props.variable}
        />
      )}

      <ComparisonGroupResultTable
        data={data}
        entriesPerPage={entriesPerPage}
        fipsAreaVisibility={fipsAreaVisibility}
        hoverLine={hoverLine}
        isActionable={props.showLineChart}
        isCollapsed={!props.expanded}
        isEmbedded={props.isEmbedded}
        onRowClick={toggleShowingFipsArea}
        pageOffset={0}
        rankingData={rankingData}
        setHoverLine={setHoverLine}
        variable={props.variable}
      />
    </div>
  );
};

export function toSortedDomainIndicatorsFipsAreaRows(
  variable: Pick<
    TrackVariable,
    'id' | 'name' | 'valueType' | 'direction' | 'dateLevel'
  >,
  fipsAreas: readonly FipsAreaWithShortName[],
  trackAreaData: readonly TrackAreaData[],
  colors: Record<string, string>
): readonly DomainIndicatorsFipsAreaRow[] {
  const trackAreaDataByFipsAreaId = _.keyBy(trackAreaData, (d) => d.fipsArea.id);

  // Transform data to add supplemental information
  const fipsAreasRows = fipsAreas.map((fipsArea) => {
    const trackAreaDataForFips = trackAreaDataByFipsAreaId[fipsArea.id];
    const recentDatum = getRecentDatum(trackAreaDataForFips);
    const dataArea = trackAreaDataForFips ? recentDatum?.fipsArea : null;
    const dataAreaFips = dataArea?.id ?? fipsArea.id;
    return {
      id: fipsArea.id,
      dataAreaFips: dataAreaFips,
      name: fipsArea.shortName ?? fipsArea.name,
      color: colors[dataAreaFips],
      trackAreaData: trackAreaDataForFips ?? [],
      variableName: variable.name,
      variableValueType: variable.valueType,
      variableDateLevel: variable.dateLevel,
      recentDatum,
      variableDirection: variable.direction,
    };
  });

  // Sort data in ranked order based on scale
  const sortByVariableDirection = fipsAreasRows.sort((a, b) => {
    if ((a.recentDatum?.value ?? 0) === (b.recentDatum?.value ?? 0)) {
      return a.name < b.name ? -1 : 1;
    }
    switch (variable.direction) {
      case VariableDirection.NEG: {
        if (!a.recentDatum?.value && b.recentDatum?.value) {
          return 1;
        }
        if (a.recentDatum?.value && !b.recentDatum?.value) {
          return -1;
        }
        return (a.recentDatum?.value ?? 0) - (b.recentDatum?.value ?? 0);
      }
      case VariableDirection.POS:
      default:
        return (b.recentDatum?.value ?? 0) - (a.recentDatum?.value ?? 0);
    }
  });

  // Add ranks to data
  let currentRank = 0;
  const variablesWithRanks = sortByVariableDirection.map((value, idx) => {
    return {
      ...value,
      rank:
        value.recentDatum?.value ===
        sortByVariableDirection[idx - 1]?.recentDatum?.value
          ? currentRank
          : ++currentRank,
    };
  });
  return variablesWithRanks;
}

function getRecentDatum(trackAreaDatum: TrackAreaData): TrackDatum | null {
  const performanceDataSortedByRecordedAt = _.sortBy(
    trackAreaDatum.performanceData,
    ({ recordedAt }) => recordedAt
  );
  if (performanceDataSortedByRecordedAt.length > 0) {
    const recentDatum =
      performanceDataSortedByRecordedAt[
        performanceDataSortedByRecordedAt.length - 1
      ];
    return recentDatum;
  }
  return null;
}
