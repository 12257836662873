/**
 * Takes raw "fraction" and turns it into a "proper percentage".
 * Examples:
 * - roundPercent(.11111, 1) => 11.1
 * - roundPercent(.5432, 1) => 54.3
 * @param percent The percentage you need rounded
 * @param numDecimalDigits How many decimals to round to
 */
export function roundPercent(percent: number, numDecimalDigits: number = 1) {
  const factor = Math.pow(10, numDecimalDigits);
  return Math.round(percent * 100 * factor) / factor;
}

interface EllipseVariables {
  readonly x: number;
  readonly y: number;
  readonly h: number;
  readonly k: number;
  readonly a: number;
  readonly b: number;
}

// Determine if point is in ellipse of equation (x-h)²/a² + (y-k)²/b² <= 1
export function determineIfPointInEllipse(vars: EllipseVariables): boolean {
  const { x, y, h, k, a, b } = vars;

  const distanceFromCenter =
    Math.pow(x - h, 2) / Math.pow(a, 2) + Math.pow(y - k, 2) / Math.pow(b, 2);
  return distanceFromCenter <= 1;
}

interface BinomialProportionSampleData {
  readonly numOccurrences: number; // Numerator of metric to compute significance on
  readonly numTotal: number; // Denominator of metric to compute significance on
}

// Performs a two-sample binomial proportion test against the provided z cut-off to determine significance
export function determineTwoSampleBinomialProportionSignificance(
  sample1: BinomialProportionSampleData,
  sample2: BinomialProportionSampleData,
  zValueCutoff: number
) {
  const sample1Percent = sample1.numOccurrences / sample1.numTotal;
  const sample2Percent = sample2.numOccurrences / sample2.numTotal;
  const combinedOccurrences = sample1.numOccurrences + sample2.numOccurrences;
  const combinedTotal = sample1.numTotal + sample2.numTotal;
  const combinedPercent = combinedOccurrences / combinedTotal;
  const groupPercentDifference = sample1Percent - sample2Percent;
  const z =
    groupPercentDifference /
    Math.sqrt(
      combinedPercent *
        (1 - combinedPercent) *
        (1 / sample1.numTotal + 1 / sample2.numTotal)
    );

  return Math.abs(z) > Math.abs(zValueCutoff);
}

export const createConsecutiveNumbers = (initial: number, quantity: number) =>
  Array.from({ length: quantity }, (_, i) => initial + 1 + i);

export const createEquallySpacedNumbers = (
  initial: number,
  quantity: number,
  difference: number
) => Array.from({ length: quantity }, (_, i) => initial + i * difference);
