import { ChartElement } from '../../chart/element';
import { D3Chart } from '../../chart';
import * as d3 from 'd3';
import { DataPoint } from '../line';

import './styles.scss';

export interface Props {
  readonly uniqueId: string;
  readonly translateX: number;
  readonly translateY: number;
  readonly height: number;
  readonly width: number;
  readonly data: readonly DataPoint[];
  readonly x: d3.ScaleTime<number, number>;
  readonly y: d3.ScaleLinear<number, number>;
  readonly onTooltipHover: Function;
  readonly tooltipSizes?: {
    readonly labelWidth: number;
    readonly labelHeight: number;
  };
  readonly getDinamicSize?: Function;
}

export interface ChartSelections {
  readonly overlay: d3.Selection<SVGRectElement, {}, null, undefined>;
  readonly focus: d3.Selection<SVGGElement, {}, null, undefined>;
}

const defaultLlabelWidth = 160,
  defaultLabelHeight = 90,
  circleRadius = 7.5;

export function mousemove(
  props: Props,
  container: d3.ContainerElement,
  focus: ChartSelections['focus'],
  event: MouseEvent
) {
  const {
    data,
    height,
    onTooltipHover,
    tooltipSizes,
    uniqueId,
    width,
    x,
    y,
    getDinamicSize,
  } = props;

  if (!data.length) {
    // All the logic below is for displaying the hover value.
    // This value shows new/total counts for the data point.
    // The logic breaks for 0 points, and there really
    // isn't any need for specific hover values when there are no
    // points, so just not showing a hover that provides no value
    // is a quick and dirty fix until this chart moves to nivo
    return;
  }

  const bisectDate = d3.bisector((datapoint) => {
    return (datapoint as DataPoint).date;
  }).left;

  const labelWidth = tooltipSizes?.labelWidth || defaultLlabelWidth;
  const labelHeight = tooltipSizes?.labelHeight || defaultLabelHeight;
  // @ts-ignore
  // We are forcing a version that actually does have `mouse`
  const x0 = x.invert(d3.pointer(event, container)[0]);
  const i = bisectDate(data, x0, 1, data.length - 1);
  const d0 = data[i - 1];
  const d1 = data[i];
  const d: DataPoint =
    data.length === 1
      ? d0
      : x0.getTime() - d0.date.getTime() > d1.date.getTime() - x0.getTime()
        ? d1
        : d0;
  focus.attr('transform', 'translate(' + x(d.date) + ',' + y(d.total) + ')');

  // innerHTML is necessary for polyfills, appending a div using d3 doesn't work
  const hoverTextElement = document.getElementById(
    `data-viz-d3-hover-focus-text-id${uniqueId}`
  );
  if (hoverTextElement) {
    // NOTE: this could become a React component eventually, but we might want to avoid
    // the re-render on every mouse move in that case
    const dinamicSizes = getDinamicSize ? getDinamicSize(d) : null;
    hoverTextElement.innerHTML = `
    <div 
      class="data-viz-d3-hover-focus-text" 
      xmlns="http://www.w3.org/1999/xhtml" 
      style="width: ${
        dinamicSizes ? dinamicSizes.labelWidth : labelWidth
      }px; height: ${dinamicSizes ? dinamicSizes.labelHeight : labelHeight}px;"
    >
      ${onTooltipHover(d)}
    </div>`;
  }

  // flip hover if it overlaps edge of chart
  let xLabel: number, yLabel: number;
  if (x(d.date) > width - labelWidth) {
    xLabel = -labelWidth - circleRadius;
  } else {
    xLabel = circleRadius;
  }
  if (y(d.total) < height - labelHeight) {
    yLabel = circleRadius;
  } else {
    yLabel = -labelHeight - circleRadius;
  }

  focus
    .select(`#data-viz-d3-hover-focus-text-id${uniqueId}`)
    .attr('x', xLabel)
    .attr('y', yLabel);

  focus.select('.data-viz-d3-hover-x-line').attr('y2', height - y(d.total));
}

export class Hover extends ChartElement<Props, ChartSelections> {
  draw(chart: D3Chart, props: Props) {
    const { height, translateX, tooltipSizes, translateY, uniqueId, width } = props;

    const labelWidth = tooltipSizes?.labelWidth || defaultLlabelWidth;
    const labelHeight = tooltipSizes?.labelHeight || defaultLabelHeight;

    const g = chart
      .append('g')
      .attr('transform', 'translate(' + translateX + ',' + translateY + ')')
      .attr('class', 'data-viz-d3-hover');

    const focus = g
      .append('g')
      .attr('class', 'data-viz-d3-hover-focus')
      .style('display', 'none');

    focus
      .append('line')
      .attr('class', `data-viz-d3-hover-x-line data-viz-d3-hover-line`)
      .attr('y1', 0)
      .attr('y2', height);

    // If we want to include a line to the y axis
    // focus.append("line")
    //   .attr("class", "data-viz-d3-y-hover-line data-viz-d3-hover-line")
    //   .attr("x1", width)
    //   .attr("x2", width);

    focus.append('circle').attr('r', circleRadius);

    // Consider moving to separate component
    focus
      .append('foreignObject')
      .attr('id', `data-viz-d3-hover-focus-text-id${uniqueId}`)
      .attr('x', circleRadius)
      .attr('y', circleRadius)
      .attr('width', labelWidth + 100)
      .attr('height', labelHeight + 100);

    const overlay = chart
      .append('rect')
      .attr('transform', 'translate(' + translateX + ',' + translateY + ')')
      .attr('class', 'data-viz-d3-hover-overlay')
      .attr('width', width)
      .attr('height', height)
      .on('mouseover', () => {
        focus.style('display', null);
      })
      .on('mouseout', () => {
        focus.style('display', 'none');
      })
      .on('mousemove', function (event: MouseEvent) {
        mousemove(props, this, focus, event);
      });

    return { overlay, focus };
  }

  update(_chart: D3Chart, props: Props, selections: ChartSelections) {
    const { width, height } = props;

    selections.overlay
      .attr('width', width)
      .attr('height', height)
      .on('mousemove', function (event: MouseEvent) {
        mousemove(props, this, selections.focus, event);
      });
  }

  clear(chart: D3Chart) {
    chart.selectAll('.hovers').on('click', null);
  }
}
