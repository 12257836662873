import * as React from 'react';
import { D3Chart } from 'client/shared/components/data-viz-d3/chart';

export interface Props {
  readonly chart: D3Chart | undefined;
}
/**
 * A React component that correlates to a sub-component of a chart and which
 * integrates d3 into the React lifecycle.
 *
 * @remarks We are considering moving this logic out of the *element* level and
 * up to the *chart* level. As such, the Chart component would essentially serve
 * as one "portal" into d3 code, and inside a d3render() function you can simply
 * use d3 style abstractions and not continue to fight against React component
 * lifecycles.
 *
 * Current:
 * ```
 <Chart bodyClassName="foo">
  {(d3Chart, width, height) =>
   (<>
      <Bar {...barProps} />
      <Axis {...yAxisProps} />
      <Axis {...xAxisProps} />
      <ValuesHover {...hoverProps} />
    </>)
  }
 </Chart>
 ```
 *
 * Proposed:
 *
 * ```
interface D3RenderProps<TPassedProps> {
  readonly passedProps: TPassedProps;
  readonly chartProps: {
   readonly width: number;
   readonly height: number;
  }
}
 
<Chart bodyClassName="foo" d3Props={allProps} d3Render={renderBar}/>

function renderBar(chart: D3ChartSelection,
  newProps: D3RenderProps<typeof allProps>,
  oldProps: D3RenderProps<typeof allProps> | null) {
  const barSelections = drawBars(chart, newProps.passedProps.barProps);
  drawAxis(chart, newProps.passedProps.yAxisProps);
  drawAxis(chart, newProps.passedProps.xAxisProps);
  // note that we can use the barSelections here directly as input
  drawHover(chart, barSelections, newProps.passedProps.hoverProps);
}
```
 *
 * d3Render function (renderBar) is called by <Chart> when d3Props changes or
 * when the size of the chart changes. Implementations can determine whether
 * to care about how the props have changed, or just use current values. This
 * allows code to disambiguate between sizing and data changes.
 *
 */
export abstract class ChartElement<TProps, TChart> extends React.Component<
  TProps & Props
> {
  private _state: {
    readonly D3ChartSelections: TChart;
    readonly chart: D3Chart;
  } | null = null;

  private drawOrUpdate() {
    if (this.props.chart) {
      if (this._state) {
        const updated = this.update(
          this._state.chart,
          this.props as TProps,
          this._state.D3ChartSelections
        );
        if (updated) {
          this._state = {
            D3ChartSelections: updated,
            chart: this._state.chart,
          };
        }
      } else {
        const chart = this.props.chart.append<SVGElement>('g' as string);
        const D3ChartSelections = this.draw(chart, this.props);
        this._state = { chart, D3ChartSelections };
      }
    }
  }
  componentDidMount() {
    this.drawOrUpdate();
  }

  componentDidUpdate() {
    this.drawOrUpdate();
  }

  componentWillUnmount() {
    if (this._state) {
      this.clear(this._state.chart);
      this._state.chart.remove();
      this._state = null;
    }
  }

  abstract draw(_chart: D3Chart, _props: TProps): TChart;

  abstract update(
    _chart: D3Chart,
    _props: TProps,
    _selections: TChart
  ): TChart | void;

  abstract clear(_chart: D3Chart): void;

  render() {
    return null;
  }
}
