import { ChartElement } from '../../chart/element';
import { D3Chart } from '../../chart';
import * as d3 from 'd3';
import { Case } from 'core';
import moment from 'moment';
import { scaleAxis } from '../../helpers/axis-helpers';

import './styles.scss';

export enum PropsTypes {
  STRING = 'STRING',
  TIME = 'TIME',
  LINEAR = 'LINEAR',
  PERCENTAGE = 'PERCENTAGE',
  PERCENTAGE_GIVEN = 'PERCENTAGE_GIVEN',
}

export enum AxisLocation {
  BOTTOM = 'axisBottom',
  LEFT = 'axisLeft',
}

export interface InnerProps {
  readonly applyWrapping?: boolean;
  readonly className?: string;
  readonly axisLocation: AxisLocation;
  readonly translateX: number;
  readonly translateY: number;
  readonly width: number;
  readonly tickSize: number;
  readonly hideAxisLine?: boolean;
  readonly hideMonthTick?: boolean;
}

const axisBarToLabelPadding = 10;

export type Props =
  | Case<
      PropsTypes.STRING,
      InnerProps & {
        readonly axis: d3.ScaleBand<string>;
        readonly tickNumber: number;
      }
    >
  | Case<
      PropsTypes.TIME,
      InnerProps & {
        readonly axis: d3.ScaleTime<number, number>;
        readonly tickValues?: readonly Date[];
      }
    >
  | Case<
      PropsTypes.LINEAR | PropsTypes.PERCENTAGE | PropsTypes.PERCENTAGE_GIVEN,
      InnerProps & {
        readonly axis: d3.ScaleLinear<number, number>;
        readonly tickNumber: number;
      }
    >;

export interface ChartSelections {
  readonly axisG: d3.Selection<SVGGElement, {}, null, undefined>;
}

type AxisG = ChartSelections['axisG'];

function wrapDate(
  domainAxis: Props['axis'],
  axisG: AxisG,
  hideMonthTick: boolean = false
) {
  // get range of axis to determine display
  const minDate = moment(domainAxis.domain()[0]);
  const maxDate = moment(domainAxis.domain()[1]);
  const dateRange = maxDate.diff(minDate, 'months');
  let line1 = '%b';
  let line2 = '%Y';
  if (dateRange < 6) {
    line1 = '%d';
    line2 = '%b';
  }
  const labels = axisG.selectAll<SVGGElement, Date>('.tick text').text(null);

  const addTspanElement = (timeFormat: string, dy: string) => {
    labels
      .append('tspan')
      .attr('x', 0)
      .attr('y', parseFloat(labels.attr('y')) + axisBarToLabelPadding)
      .attr('dy', dy)
      .text(d3.timeFormat(timeFormat));
  };

  if (labels.size() > 0) {
    const labelDy = labels.attr('dy');

    if (hideMonthTick) {
      addTspanElement('%Y', labelDy);
    } else {
      addTspanElement(line1, labelDy);
      addTspanElement(line2, parseFloat(labelDy) + 1.1 + 'em');
    }
  }

  return axisG;
}

function wrapString(axisG: AxisG) {
  const labels = axisG.selectAll<SVGGElement, String>('.tick text').text(null);

  labels.call((label) => {
    label.each(function (lab) {
      const textElement = d3.select(this);
      const tooltipText = ' *';
      const shouldShowTooltip = lab.includes(tooltipText);
      const labelText = lab.replace(tooltipText, '');
      const words = labelText.split(/\s+/);
      const doesFirstWordFit = words[0].length > 7;

      const addTspanElement = (text: string) => {
        textElement
          .append('tspan')
          .attr('x', 0)
          .attr('y', parseFloat(textElement.attr('y')) + axisBarToLabelPadding)
          .attr('dy', textElement.attr('dy'))
          .text(text);
      };

      if (doesFirstWordFit || words.length > 3) {
        const firstWord = !doesFirstWordFit
          ? `${words.shift()} ${words.shift()}`
          : words.shift() || '';
        const secondWord = words.join(' ');

        addTspanElement(firstWord);

        if (secondWord) {
          textElement
            .append('tspan')
            .attr('x', 0)
            .attr(
              'y',
              parseFloat(textElement.attr('y')) + axisBarToLabelPadding + 20
            )
            .attr('dy', textElement.attr('dy'))
            .text(secondWord);
        }
      } else {
        addTspanElement(labelText.toString());
      }

      if (shouldShowTooltip) {
        textElement.append('tspan').style('fill', 'red').text(tooltipText);
      }
    });
  });

  return axisG;
}

function drawAxis(axisG: AxisG, props: Props) {
  const { width, tickSize, translateX, translateY } = props;

  axisG.attr('transform', 'translate(' + translateX + ',' + translateY + ')');
  const checkIfHidesDomainLine = () => {
    if (props.hideAxisLine) {
      axisG.call((g) => g.select('.domain').remove());
    }
  };
  switch (props.type) {
    case PropsTypes.STRING:
      axisG.call(
        d3[props.axisLocation](props.axis)
          .tickSize(tickSize)
          .tickSizeOuter(0)
          .tickPadding(axisBarToLabelPadding)
          .ticks(props.tickNumber)
      );
      checkIfHidesDomainLine();
      return { axisG: !!props.applyWrapping ? wrapString(axisG) : axisG };

    case PropsTypes.TIME:
      props.axis.range([0, width]);
      axisG.call(
        d3[props.axisLocation](props.axis)
          .tickSize(tickSize)
          .tickSizeOuter(0)
          .tickValues(
            props.tickValues
              ? props.tickValues.map((d) => new Date(d))
              : scaleAxis(width, props.axis)
          )
      );
      checkIfHidesDomainLine();
      return { axisG: wrapDate(props.axis, axisG, props.hideMonthTick) };

    case PropsTypes.LINEAR:
      axisG.call(
        d3[props.axisLocation](props.axis)
          .tickSize(tickSize)
          .tickSizeOuter(0)
          .ticks(props.tickNumber)
      );
      checkIfHidesDomainLine();
      return { axisG };

    case PropsTypes.PERCENTAGE:
    case PropsTypes.PERCENTAGE_GIVEN:
      axisG.call(
        d3[props.axisLocation](props.axis)
          .tickSize(tickSize)
          .tickSizeOuter(0)
          .ticks(props.tickNumber)
          .tickFormat((val) => val + '%')
      );
      checkIfHidesDomainLine();
      return { axisG };
  }
}
export class Axis extends ChartElement<Props, ChartSelections> {
  draw(chart: D3Chart, props: Props) {
    const axisG = chart
      .append('g')
      .classed(`data-viz-d3-axis-container ${props.className || ''}`, true);

    return drawAxis(axisG, props);
  }

  update(_chart: D3Chart, props: Props, selections: ChartSelections) {
    drawAxis(selections.axisG, props);
  }

  clear(chart: D3Chart) {
    chart.selectAll('.axis').on('click', null);
  }
}
