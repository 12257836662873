import * as React from 'react';
import * as d3 from 'd3';
import ReactResizeDetector from 'react-resize-detector';

export type D3Chart = d3.Selection<SVGElement, {}, null, undefined>;

export interface Props {
  readonly className?: string;
  readonly svgClassName?: string;
  readonly bodyClassName?: string;
  readonly children: (
    chart: D3Chart | undefined,
    width: number,
    height: number
  ) => React.ReactNode;
}

export interface State {
  readonly chart: D3Chart | null;
  readonly forcedRefresh: boolean;
}

const timedRefresh = process.env.NODE_ENV !== 'test';

export class Chart extends React.Component<Props, State> {
  private svgRef?: SVGElement | null;
  mounted: boolean = false;
  state: State = {
    chart: null,
    forcedRefresh: !timedRefresh,
  };

  componentDidMount() {
    this.mounted = true;

    if (this.svgRef) {
      this.setState({
        chart: d3.select(this.svgRef),
      });
    }

    if (this.state.chart) {
      this.draw(this.state.chart, this.props);
    }
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate(_prevProps: Props) {
    if (this.state.chart) {
      this.draw(this.state.chart, this.props);
    }
  }

  draw(_chart: D3Chart, _props: Props) {}

  private determineSizeAndRender(width?: number, height?: number): React.ReactNode {
    if (!width || !height || !this.state.forcedRefresh) {
      const cb = () => this.mounted && this.setState({ forcedRefresh: true });
      window.setTimeout(cb, 200);
      return null;
    }

    const useWidth = width || (this.svgRef ? this.svgRef.clientWidth : 0);
    const useHeight = height || (this.svgRef ? this.svgRef.clientHeight : 0);
    if (this.state.chart && useWidth && useHeight) {
      return this.props.children(this.state.chart, useWidth, useHeight);
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className={`pn-chart-d3 ${this.props.bodyClassName || ''}`}>
        <ReactResizeDetector handleHeight handleWidth>
          {({
            width,
            height,
          }: {
            readonly width: number;
            readonly height: number;
          }) => {
            return (
              <div className="h-100 w-100">
                <svg
                  className={`${this.props.svgClassName || ''}`}
                  height={height}
                  ref={(ref) => (this.svgRef = ref)}
                  width={width}
                >
                  {this.determineSizeAndRender(width, height)}
                </svg>
              </div>
            );
          }}
        </ReactResizeDetector>
      </div>
    );
  }
}
