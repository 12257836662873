import {
  Point,
  PointSymbolProps,
  PointTooltipProps,
  ResponsiveLine,
  Serie,
} from '@nivo/line';
import { ScaleSpec } from '@nivo/scales';
import _, { floor, range } from 'lodash';
import React from 'react';
import './styles.scss';
import { DomainIndicatorsFipsAreaRow } from '../domain-indicators-group-wrapper';
import { formatIndicatorValue } from 'client/shared/core/performance-data';
import { AnalyticsValueType, DateLevel, StatisticType, TrackVariable } from 'core';
import moment from 'moment';
import { useId } from 'react-use-id-hook';

interface Props {
  readonly variable: Pick<
    TrackVariable,
    'id' | 'name' | 'valueType' | 'direction' | 'dateLevel' | 'statisticType'
  >;
  readonly data: readonly DomainIndicatorsFipsAreaRow[];
  readonly fipsAreaVisibility: Record<string, boolean>;
  readonly hoverLine: string | null;
  readonly setHoverLine: (questionId: string | null) => void;
}

const baseClass = 'pn-domain-indicators-group-line-chart';

export const ComparisonGroupLineChart: React.FC<Props> = (p) => {
  const { setHoverLine, hoverLine } = p;
  const tooltipId = useId();
  const { yAxisValues, yAxisScaleProps, xAxisValues, xAxisScaleProps } =
    React.useMemo<{
      readonly yAxisValues: number[];
      readonly yAxisScaleProps: ScaleSpec;
      readonly xAxisValues: number[] | Date[];
      readonly xAxisScaleProps: ScaleSpec;
    }>(() => {
      const minY =
        p.variable.statisticType === StatisticType.INDEX
          ? 0
          : _.min(
              p.data.flatMap(
                (d) =>
                  d.trackAreaData.performanceData?.map(({ value }) => value) ?? []
              )
            ) ?? 0;
      const maxY =
        p.variable.statisticType === StatisticType.INDEX
          ? 90
          : _.max(
              p.data.flatMap(
                (d) =>
                  d.trackAreaData.performanceData?.map(({ value }) => value) ?? []
              )
            ) ?? 100;

      const yValues = getYAxisValues(minY, maxY);
      const yScale: ScaleSpec = {
        type: 'linear',
        min: Math.min(...yValues),
        max: Math.max(...yValues),
      };
      const minX = _.min(
        p.data.flatMap(
          (d) =>
            d.trackAreaData.performanceData?.map(({ recordedAt }) => recordedAt) ??
            []
        )
      );

      const maxX = _.max(
        p.data.flatMap(
          (d) =>
            d.trackAreaData.performanceData?.map(({ recordedAt }) => recordedAt) ??
            []
        )
      );
      const xScale: ScaleSpec =
        p.variable.dateLevel === DateLevel.YEAR
          ? {
              type: 'linear',
              min: minX?.getUTCFullYear() ?? new Date().getUTCFullYear() - 1,
              max: maxX?.getUTCFullYear() ?? new Date().getUTCFullYear(),
            }
          : { type: 'time', min: minX, max: maxX };

      const xValues = getXAxisValuesByDateLevel(minX, maxX, p.variable.dateLevel);

      return {
        yAxisValues: yValues,
        yAxisScaleProps: yScale,
        xAxisValues: xValues,
        xAxisScaleProps: xScale,
      };
    }, [p.variable, p.data]);
  const pointHoverEvent = React.useCallback(
    (point: Point) => {
      setHoverLine(point.serieId as string);
    },
    [setHoverLine]
  );
  const mouseOutEvent = React.useCallback(() => {
    setHoverLine(null);
  }, [setHoverLine]);
  const rows = React.useMemo(() => {
    return transformData(p.data, p.variable.dateLevel);
  }, [p.data, p.variable]);
  const { rowsShown, colorsShown } = React.useMemo<{
    readonly rowsShown: Serie[];
    readonly colorsShown: string[];
  }>(() => {
    const visibleRows = rows.filter((row) => p.fipsAreaVisibility[row.fips]);
    const colors = visibleRows.map((row) => row.color);
    return {
      rowsShown: visibleRows,
      colorsShown: colors,
    };
  }, [rows, p.fipsAreaVisibility]);
  const pointSymbolFn = React.useCallback(
    (point: Readonly<PointSymbolProps>) => {
      return point.datum.fips === hoverLine || point.datum.isSinglePoint ? (
        <circle fill={point.color} r={7} stroke={'black'} />
      ) : null;
    },
    [hoverLine]
  );
  const tooltipFn = React.useCallback(
    (tooltipProps: React.PropsWithChildren<PointTooltipProps>) => {
      const dataByDataAreaFips = _.keyBy(p.data, ({ dataAreaFips }) => dataAreaFips);
      const { point } = tooltipProps;
      const dataArea =
        dataByDataAreaFips[point.serieId].trackAreaData.performanceData[0].fipsArea;
      return (
        <div
          className="d-flex flex-column align-items-center"
          id={tooltipId}
          style={{
            background: 'white',
            padding: '9px 12px',
            border: '1px solid #ccc',
            borderRadius: '5px',
          }}
        >
          <div className="d-flex flex-row align-items-center">
            <svg className={`${baseClass}-tooltip mr-1`}>
              <circle cx={8} cy={8} fill={point.color} r={8} />
            </svg>
            <p>
              {formatXAxisValue(point.data.x, p.variable.dateLevel)}:{' '}
              <b>
                {formatValueByStatisticType({
                  value: +point.data.yFormatted.toString(),
                  statisticType: p.variable.statisticType,
                  valueType: p.variable.valueType,
                })}
              </b>
            </p>
          </div>
          <div className="tooltip-row-name d-flex font-size-xs">{dataArea.name}</div>
        </div>
      );
    },
    [p.data, p.variable, tooltipId]
  );

  //TO-DO create data unavailable component if min or max year is unavailable
  if (!xAxisValues.length) {
    return <></>;
  }

  return (
    <div>
      <div className={`${baseClass}-full-chart`}>
        <ResponsiveLine
          axisBottom={{
            tickValues: xAxisValues,
            format: (tick) => formatXAxisValue(tick, p.variable.dateLevel),
            tickSize: 0,
            tickPadding: 10,
          }}
          axisLeft={{
            tickValues: yAxisValues,
            format: (value: number) =>
              formatValueByStatisticType({
                value,
                valueType: p.variable.valueType,
                statisticType: p.variable.statisticType,
                compactDisplay: true,
              }),
            tickSize: 0,
            tickPadding: 20,
          }}
          colors={colorsShown}
          data={rowsShown}
          enableGridX={false}
          enableGridY={true}
          gridYValues={yAxisValues}
          isInteractive
          layers={[
            'markers',
            'grid',
            'axes',
            'areas',
            'lines',
            'points',
            'slices',
            'mesh',
          ]}
          lineWidth={3}
          margin={{ top: 30, right: 40, bottom: 50, left: 65 }}
          onMouseLeave={mouseOutEvent}
          onMouseMove={pointHoverEvent}
          pointSize={0}
          pointSymbol={pointSymbolFn}
          theme={{
            grid: {
              line: {
                stroke: '#c6cbc8',
                strokeWidth: 1,
                strokeDasharray: '7 5',
              },
            },

            axis: {
              ticks: {
                text: {
                  fontSize: 14,
                  fontFamily: 'General Grotesque',
                  fill: '#464b47',
                },
              },
            },
          }}
          tooltip={tooltipFn}
          useMesh
          xScale={xAxisScaleProps}
          yScale={yAxisScaleProps}
        />
      </div>
    </div>
  );
};

function transformData(
  rows: readonly DomainIndicatorsFipsAreaRow[],
  dateLevel: DateLevel

  // Disabling readonly rule because charting library does not like readonly arrays
  // eslint-disable-next-line functional/prefer-readonly-type
): Serie[] {
  return rows.map((row) => {
    const performanceDataLength = row.trackAreaData.performanceData?.length ?? 0;

    switch (dateLevel) {
      case DateLevel.MONTH: {
        return {
          id: row.dataAreaFips,
          color: row.color,
          fips: row.dataAreaFips,
          data: performanceDataLength
            ? row.trackAreaData.performanceData
                ?.map((h) => ({
                  x: h.recordedAt,
                  y: h.value,
                  isSinglePoint: performanceDataLength === 1,
                  fips: row.dataAreaFips,
                }))
                .sort((a, b) => a.x.getTime() - b.x.getTime())
            : [],
        };
      }
      case DateLevel.YEAR: {
        return {
          id: row.dataAreaFips,
          color: row.color,
          fips: row.dataAreaFips,
          data: performanceDataLength
            ? row.trackAreaData.performanceData
                ?.map((h) => ({
                  x: h.recordedAt.getUTCFullYear(),
                  y: h.value,
                  isSinglePoint: performanceDataLength === 1,
                  fips: row.dataAreaFips,
                }))
                .sort((a, b) => a.x - b.x)
            : [],
        };
      }
    }
  });
}

function formatXAxisValue(value: number | string | Date, dateLevel: DateLevel) {
  switch (dateLevel) {
    case DateLevel.MONTH:
      return moment(value).utc().format('MMM YYYY');
    case DateLevel.YEAR:
      return value;
  }
}
function getXAxisValuesByDateLevel(
  minX: Date | undefined,
  maxX: Date | undefined,
  dateLevel: DateLevel
) {
  if (!minX || !maxX) {
    return [];
  }
  const years = range(minX.getUTCFullYear(), maxX.getUTCFullYear() + 1).map(
    (v) => new Date(`01/01/${v}`)
  );
  const modNumber = _.floor(years.length / 10) * 2;
  if (modNumber > 0) {
    const tenYearsRange = range(
      minX.getUTCFullYear(),
      maxX.getUTCFullYear() - (maxX.getUTCFullYear() % modNumber) + modNumber
    ).map((v) => new Date(`01/01/${v}`));
    const yearsMod = tenYearsRange.filter((y) => {
      return y.getUTCFullYear() % modNumber === 0;
    });
    switch (dateLevel) {
      case DateLevel.MONTH:
        return yearsMod;
      case DateLevel.YEAR:
        return yearsMod.map((v) => v.getUTCFullYear());
    }
  }

  switch (dateLevel) {
    case DateLevel.MONTH: {
      const yearRange = range(minX.getUTCFullYear(), maxX.getUTCFullYear() + 1);
      const quarterMonths = yearRange.flatMap((y) => {
        const jan = new Date(`01/01/${y}`);
        const apr = new Date(`04/01/${y}`);
        const jul = new Date(`07/01/${y}`);
        const oct = new Date(`10/01/${y}`);
        return [jan, apr, jul, oct];
      });
      if (quarterMonths.length > 16) {
        return years;
      }
      return quarterMonths;
    }
    case DateLevel.YEAR:
      return years.map((v) => v.getUTCFullYear());
  }
}

function getYAxisValues(minY: number, maxY: number) {
  if (maxY - minY <= 5) {
    return range(_.floor(maxY) <= 9 ? 0 : floor(minY), maxY + 1, 0.5);
  }
  const rangeValues = range(_.floor(minY / 10) * 10, _.floor((maxY + 10) / 10) * 10);
  const modNumber =
    rangeValues.length % 10
      ? _.floor(rangeValues.length / 10) + 1
      : _.floor(rangeValues.length / 10);

  const modRange = rangeValues.filter((v) => {
    return v % modNumber === 0;
  });
  return _.uniq([
    modRange[0] - modNumber < 0 ? 0 : modRange[0] - modNumber,
    ...modRange,
    modRange[modRange.length - 1] + modNumber,
  ]);
}
function formatValueByStatisticType(args: {
  readonly value: number;
  readonly statisticType: StatisticType;
  readonly valueType: AnalyticsValueType;
  readonly compactDisplay?: boolean;
}) {
  const { value, statisticType, valueType, compactDisplay } = args;
  switch (statisticType) {
    case StatisticType.INDEX:
      return _.round(value).toString();
    case StatisticType.INDICATOR:
    case StatisticType.SENTIMENT_VALUE:
      return formatIndicatorValue(value, valueType, 2, false, compactDisplay);
  }
}

ComparisonGroupLineChart.displayName = 'ComparisonGroupLineChart';
