import {
  Case,
  Flavor,
  unionOfEnum,
  WordCount,
  MetaCondition,
  QuestionChoiceCondition,
  TrendValue,
  BenchmarkValue,
  AverageChoiceValueData,
  ScaleType,
  isDateToday,
  QuestionSetStatus,
  ClientSavedVisualization,
  ClientVisualizationId,
  SimulationType,
} from 'core';
import { ClientPublishingEntityId } from '../publishing-entity';
import * as Gql from 'client/shared/graphql-client/graphql-operations.g';
import { errorLogger } from '../error-handler';
import { SimulationNodeData } from '../balancing-act-simulation';

export type ClientQuestionId = Flavor<string, 'Question'>;
export type ClientShareableQuestionChoiceSetId = Flavor<
  string,
  'ShareableQuestionChoiceSet'
>;
export type ClientQuestionChoiceId = Flavor<string, 'QuestionChoice'>;
export type ClientVoteId = Flavor<string, 'VoteId'>;

export enum QuestionType {
  // POLICY = 'policy',
  // PETITION = 'petition',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  GRID_CHOICE = 'GRID_CHOICE',
  POINT_ALLOCATION = 'POINT_ALLOCATION',
  FREE_TEXT = 'FREE_TEXT',
}

export enum PositionType {
  SECTION_HEADER = 'section_header',
  VISUALIZATION = 'visualization',
}

export type SurveyItemDataType = QuestionType | PositionType | SimulationType;

export interface QuestionChoice {
  readonly id: ClientQuestionChoiceId;
  readonly label: string;
  readonly choiceValue?: number | null;
}

export interface GridRow {
  readonly id: ClientQuestionId;
  readonly label: string;
}

export enum QuestionStatus {
  DRAFT = 'DRAFT',
  CART = 'CART',
  SCHEDULED = 'SCHEDULED',
  PUBLISHED = 'PUBLISHED',
  CLOSED = 'CLOSED',
  ARCHIVED = 'ARCHIVED',
  SOFT_DELETED = 'SOFT_DELETED',
  HISTORIC_RECORD = 'HISTORIC_RECORD',
}

export enum QuestionCategory {
  DRAFT = 'draft',
  RECOMMENDED = 'recommended',
}

export interface QuestionSchedule {
  readonly openDate: Date | null;
  readonly closeDate: Date | null;
}

export interface SavedQuestion {
  readonly id: ClientQuestionId;
  readonly category?: QuestionCategory;
  readonly title: string;
  readonly description: string | null;
  readonly images: ReadonlyArray<string>; // URLs
  readonly typedData: QuestionTypedData;
  readonly status: QuestionStatus;
  readonly shortId: string | null;
  readonly optional: boolean;
  readonly reportDisplayLabel?: string | null;
  readonly isBenchmarkedQuestion?: boolean;
}

export interface QuestionConditions {
  readonly metaConditions: readonly MetaCondition[];
  readonly questionChoiceConditions: readonly QuestionChoiceCondition[];
}

export const DEFAULT_QUESTION_CONDITIONS = {
  metaConditions: [],
  questionChoiceConditions: [],
};

export interface ChoiceSetDataDictionary {
  readonly scaleType: ScaleType;
  readonly scaleThreshold: number;
}

export interface QuestionWithExtendedData extends SavedQuestion {
  readonly conditions: QuestionConditions;
  readonly demographicAttribute: {
    readonly id: string;
    readonly name: string | null;
    readonly premium: boolean;
    readonly owner: { readonly id: ClientPublishingEntityId } | null;
  } | null;
}

// Grid or Section Header
export interface SavedHierarchy {
  readonly id: string;
  readonly category?: QuestionCategory; // TODO: enforce when integrated with GQL
  readonly title: string;
  readonly descriptionHtml: string | null;
  readonly typedData: PositionTypedData;
  readonly questions: readonly SavedQuestion[];
  readonly conditions: QuestionConditions;
}

export interface SurveyItemVisualization {
  readonly id: string;
  readonly visualization: ClientSavedVisualization | null;
  readonly conditions: QuestionConditions;
  readonly label: string | null;
}

export interface SurveyItemSimulation {
  readonly id: string;
  readonly simulation: SimulationNodeData | null;
  readonly conditions: QuestionConditions;
  readonly simulationType: SimulationType;
}

export enum SurveyItemType {
  HEADER = 'HEADER',
  QUESTION = 'QUESTION',
  VISUALIZATION = 'VISUALIZATION',
  SIMULATION = 'SIMULATION',
}

export interface QuestionTypedData_FreeText {
  readonly variableName?: string | null;
}

export interface QuestionTypedData_MultipleChoice {
  readonly variableName?: string | null;
  readonly choices: readonly QuestionChoice[];
  readonly maxSelection: number;
  readonly randomizeChoices?: boolean | null;
  readonly dataDictionary?: ChoiceSetDataDictionary | null;
  readonly shareableQuestionChoiceSetId: ClientShareableQuestionChoiceSetId | null;
}
export interface QuestionTypedData_PointAllocation {
  readonly variableName?: string | null;
  readonly choices: readonly QuestionChoice[];
  readonly dataDictionary?: ChoiceSetDataDictionary | null;
  readonly shareableQuestionChoiceSetId: ClientShareableQuestionChoiceSetId | null;
}

export interface QuestionTypedData_GridChoice {
  readonly rows: readonly QuestionTypedData_GridRow[];
  readonly columns: readonly QuestionChoice[];
  readonly dataDictionary?: ChoiceSetDataDictionary | null;
  readonly shareableQuestionChoiceSetId: ClientShareableQuestionChoiceSetId | null;
  readonly randomizeChoices?: boolean | null;
}

export interface QuestionTypedData_GridRow {
  readonly questionId: ClientQuestionId;
  readonly label: string;
  readonly variableName?: string | null;
  readonly conditions: QuestionConditions;
  readonly reportDisplayLabel?: string | null;
}

export interface PositionTypedData_SectionHeader {
  readonly label: string;
  readonly description: string;
}
export interface PositionTypedData_GridHeader {
  readonly label: string;
  readonly description: string;
}

export type QuestionTypedData =
  | Case<QuestionType.FREE_TEXT, QuestionTypedData_FreeText>
  | Case<QuestionType.MULTIPLE_CHOICE, QuestionTypedData_MultipleChoice>
  | Case<QuestionType.GRID_CHOICE, QuestionTypedData_GridChoice>
  | Case<QuestionType.POINT_ALLOCATION, QuestionTypedData_PointAllocation>;

export const QuestionTypedData = unionOfEnum(QuestionType, {
  ...QuestionType,
}).andType<QuestionTypedData>();

export type PositionTypedData = Case<
  PositionType.SECTION_HEADER,
  PositionTypedData_SectionHeader
>;

export type SavedSurveyItem =
  | {
      readonly type: SurveyItemType.HEADER;
      readonly data: SavedHierarchy;
    }
  | {
      readonly type: SurveyItemType.VISUALIZATION;
      readonly data: SurveyItemVisualization;
    }
  | {
      readonly type: SurveyItemType.SIMULATION;
      readonly data: SurveyItemSimulation;
    }
  | {
      readonly type: SurveyItemType.QUESTION;
      readonly questionNumber: number;
      readonly data: QuestionWithExtendedData;
    };

export interface RandomizedSurveyItems {
  readonly id: string;
  readonly choices: readonly string[];
}

export enum OpenQuestionTabs {
  RESULTS = 'Results',
  COMMENTS = 'Comments',
  INFORMATION = 'Information',
  ENDDATE = 'End Date',
  // RESOLUTION = 'Resolution'
}

export enum QuestionModalStates {
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  SAVE = 'SAVE',
}

export type QuestionModalState =
  | Case<QuestionModalStates.DELETE>
  | Case<QuestionModalStates.EDIT>
  | Case<QuestionModalStates.SAVE, { readonly question: SavedQuestion }>;

/**
 * Tab options for the question results page.
 */
export enum OpenResultsTabs {
  PARTICIPATION = 'participation',
  ADVANCED_RESULTS = 'advanced results',
  COMMENTS = 'comments',
}

export interface OpenResultsQuestion {
  readonly questionId: ClientQuestionId;
  readonly questionType: QuestionType;
  readonly viewOnly: boolean | null;
  readonly isExample: boolean;
  readonly activeTab?: OpenResultsTabs;
}

export type DiscreteQuestionResults =
  | Case<QuestionType.MULTIPLE_CHOICE, MultipleChoiceResults>
  | Case<QuestionType.GRID_CHOICE, GridResults>
  | Case<QuestionType.POINT_ALLOCATION, PointAllocationResults>;

export type QuestionResults =
  | Case<QuestionType.FREE_TEXT, FreeTextResults>
  | DiscreteQuestionResults;

export interface TrendHistoricalDataPoint {
  readonly date: Date;
  readonly aggregateValue: number;
  readonly averageChoiceValueData: AverageChoiceValueData | null;
}

export interface TrendData {
  readonly value: TrendValue;
  readonly historicalDataPoints: readonly TrendHistoricalDataPoint[];
  readonly trendSignificance: number | null;
}

export function createTrendDataPoint(
  aggregateValue: number | null,
  questionSetCloseDate: Date | null,
  averageChoiceValueData: AverageChoiceValueData | null
): TrendHistoricalDataPoint | null {
  if (!aggregateValue || !questionSetCloseDate) {
    return null;
  }
  return {
    date: questionSetCloseDate,
    aggregateValue,
    averageChoiceValueData,
  };
}

export interface BenchmarkData {
  readonly value: BenchmarkValue;
  readonly rank: number;
  readonly totalDataPoints: number;
  readonly dateRange: {
    readonly start: Date;
    readonly end: Date;
  };
  readonly benchmarkSignificance: number | null;
}
export interface MultipleChoiceResults {
  readonly hasResponses: boolean;
  readonly byChoice: readonly VotingChoice[];
  readonly choicesAreScaled: boolean;
  readonly trend: TrendData | null;
  readonly benchmark: BenchmarkData | null;
  readonly aggregateValue: number | null;
  readonly averageChoiceValueData: AverageChoiceValueData | null;
}

export type PointAllocationResults = MultipleChoiceResults;
export interface VotingChoice {
  readonly identifier: ChoiceIdentifier;
  readonly choice: {
    readonly id: string;
    readonly text: string;
    readonly choiceValue: number | null;
  };
  readonly result: number; // 0 to 1
  readonly votesCount: number;
}

export type OverviewGridResult = Pick<VotingChoice, 'choice' | 'result'>;
export interface FreeTextResponse {
  readonly id: string;
  readonly text: string;
}

export interface FreeTextResults {
  readonly hasResponses: boolean;
  readonly responses: readonly FreeTextResponse[] | null;
  readonly wordCount: readonly WordCount[] | null;
}

export interface GridResults {
  readonly results: readonly GridResultRow[];
}

export interface GridResultRow {
  readonly rowId: string;
  readonly rowVariableName: string | null;
  readonly rowName: string;
  readonly isBenchmarkedQuestion?: boolean;
  readonly totalResponses: number;
  readonly byChoice: readonly VotingChoice[];
  readonly aggregateValue: number | null;
  readonly averageChoiceValueData: AverageChoiceValueData | null;
  readonly trend: {
    readonly value: TrendValue | null;
    readonly historicalDataPoints?: readonly TrendHistoricalDataPoint[];
  } | null;
  readonly benchmark: {
    readonly value: BenchmarkValue;
  } | null;
}

export enum ChoiceIdentifier {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
}

export const LETTER_A = 65;

export function choiceIdentifier(index: number): ChoiceIdentifier {
  return String.fromCharCode('A'.charCodeAt(0) + index) as ChoiceIdentifier;
}

export function scrollIdForSurveyItem(
  questionId: ClientQuestionId | ClientVisualizationId
): string {
  return `scroll-to-${questionId}`;
}

export const RECOMMENDED_MAX_CHOICES = 6;
export const MULTIPLE_CHOICE_QUESTION_DROPDOWN_MIN_CHOICES = 10;

export const getUpdateScheduleContentType = (
  schedule: QuestionSchedule,
  status: QuestionStatus | QuestionSetStatus
) => {
  const isCloseNow = schedule.closeDate ? new Date() >= schedule.closeDate : false;

  switch (status) {
    case QuestionStatus.CART:
    case QuestionStatus.DRAFT:
    case QuestionSetStatus.NOT_STARTED:
    case QuestionSetStatus.SCHEDULED:
    case QuestionStatus.SCHEDULED:
      if (!schedule.openDate || isDateToday(schedule.openDate)) {
        return Gql.QuestionSchedule_Type.PUBLISH_NOW;
      } else {
        return Gql.QuestionSchedule_Type.SCHEDULE_FUTURE;
      }
    case QuestionSetStatus.IN_PROGRESS:
    case QuestionStatus.PUBLISHED:
    case QuestionSetStatus.CLOSED:
    case QuestionStatus.CLOSED:
      if (isCloseNow) {
        return Gql.QuestionSchedule_Type.CLOSE_NOW;
      } else {
        return Gql.QuestionSchedule_Type.EXTEND;
      }
    default:
      errorLogger.log(`Scheduling wizard used on unexpected state: ${status}`);
      // Take a best guess at a default type for unexpected operations
      // Chances are server will throw an error
      if (isCloseNow) {
        return Gql.QuestionSchedule_Type.CLOSE_NOW;
      } else {
        return Gql.QuestionSchedule_Type.EXTEND;
      }
  }
};
