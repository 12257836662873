import * as d3 from 'd3';
import moment from 'moment';

/**
 * Find the closest factor for the rounded step - distance between each tick
 * This creates even tick increments whenever possible
 * Ex: an axis 20 days long and a step of 8, will increment up and down
 *   hitting the nearest factor of 10 and returning a date step of 10 days
 * Ex 2: an axis of 19 days and a step of 8, it will loop without finding a factor
 *   because 19 is prime and will return the original date step of 8
 * There is currently no equivalent function in D3 or lodash
 * Won't return 1 or the number that is being factored
 */
export function getClosestFactor(
  toBeFactored: number, // axis date range
  step: number // rounded tick distance
): number {
  const max = step / toBeFactored < 0.5 ? toBeFactored - step - 1 : step - 2;
  for (let i = 0; i < max; i++) {
    if (step + i < toBeFactored && toBeFactored % (step + i) === 0) {
      return step + i;
    } else if (
      step - i > 1 &&
      toBeFactored % (step - i) === 0 &&
      i / step < 0.7 // Prevent excessive ticks
    ) {
      return step - i;
    }
  }
  return step; // it's prime
}

/**
 * D3's scaling lets a specific set of ticks be specified, but doesn't stick to it
 * So the number/spacing of ticks varies a lot with resizing
 * It also created a spacing issue for the end of months with an odd amount of days
 */
export function scaleAxis(axisSize: number, axis: d3.ScaleTime<number, number>) {
  const tickCount = axisSize / 100; // aiming for 100px spaced ticks
  const [minDate, maxDate] = axis.domain();

  // In order to align the hover day with an actual day or month,
  // need to get the date range at the level we want to display the axis
  let dateRange = moment(maxDate).diff(moment(minDate), 'years');
  let dateRangeInterval: moment.unitOfTime.Base = 'years';
  if (dateRange < tickCount) {
    dateRangeInterval = 'months';
    dateRange = moment(maxDate).diff(moment(minDate), dateRangeInterval);
  }
  if (dateRange < tickCount) {
    dateRangeInterval = 'days';
    dateRange = moment(maxDate).diff(moment(minDate), dateRangeInterval);
  }

  // Sometimes this rounds down to 0, force a step minimum of 1
  const step = Math.max(1, Math.round(dateRange / tickCount));

  // Attempt to get a multiple/factor of the date range
  const factoredStep = getClosestFactor(dateRange, step);

  // d3.range needs milliseconds
  const stepMs: number = moment
    .duration(factoredStep, dateRangeInterval)
    .asMilliseconds();

  const tickValues = d3
    .range(minDate.getTime(), maxDate.getTime(), stepMs)
    .map((d) => new Date(d));

  // Add a end date if there is a large gap
  const endDateDiff = moment
    .duration(moment(maxDate).diff(moment(tickValues[tickValues.length - 1])))
    .as(dateRangeInterval);

  if (endDateDiff / factoredStep > 0.5) {
    tickValues.push(maxDate);
  }

  return tickValues;
}
