import * as React from 'react';
import moment from 'moment';
import _ from 'lodash';
import * as d3 from 'd3';
import { Chart, Props as ChartProps } from '../chart';
import {
  Axis,
  PropsTypes,
  AxisLocation,
} from 'client/shared/components/data-viz-d3/base/axis';
import { Line, DataPoint } from 'client/shared/components/data-viz-d3/base/line';
import { Hover } from 'client/shared/components/data-viz-d3/base/hover';
import { TransitionConfig } from 'client/shared/components/data-viz-d3/base/core';
import {
  createConsecutiveNumbers,
  createEquallySpacedNumbers,
  MMM_YEAR_FORMAT,
  wrap,
  YEAR_FORMAT,
} from 'core';
import { NullOverlay } from 'client/shared/components/data-viz-d3/null-overlay';

const BIG_TOOLTIP_SIZES = { labelWidth: 200, labelHeight: 102 };
const SMALL_TOOLTIP_SIZES = { labelWidth: 200, labelHeight: 60 };

export enum TimeBreakdownInterval {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export interface TimeBreakdown {
  readonly interval: TimeBreakdownInterval;
  readonly data: readonly {
    readonly time: Date;
    readonly value: number;
    readonly formattedValue?: string;
    readonly additionalData?: DataPoint['additionalData'];
  }[];
  readonly isIndicator?: boolean;
}

export interface Props extends Omit<ChartProps, 'children'> {
  readonly uniqueId: string;
  readonly hideAxisLines?: boolean;
  readonly hideMonthTick?: boolean;
  readonly hideYTick?: boolean;
  readonly hideXTick: boolean;
  readonly times: TimeBreakdown | null;
  // interior margin for g element
  readonly innerMargin: {
    readonly top: number;
    readonly right: number;
    readonly bottom: number;
    readonly left: number;
  };
  readonly transition?: TransitionConfig;
  readonly entityLabel: string;
  readonly yMaxValue?: number;
  readonly yMinValue?: number;
  readonly lineColor?: string;
  readonly xMaxTicksQuantity?: number;
  readonly hasIndicators?: boolean;
  readonly maxXDataPoints?: number;
}

const getSubscribersData = (times: TimeBreakdown | null): readonly DataPoint[] => {
  const dataSum: readonly DataPoint[] = (times?.data || []).map((d, i) => {
    return {
      date: d.time,
      hoverValue: d.value,
      total: d3.sum((times?.data || []).slice(0, i + 1), (v) => v.value),
    };
  });

  // Remove any subscriber times that are older than a week before hitting 10 subscribers
  // If allowing user control of bounds, will need to change x-axis instead
  const subscribersCutoff = dataSum.find((d) => {
    return d.total >= 10;
  });
  const trimmedData = subscribersCutoff
    ? dataSum.filter((d) => {
        return d.date > moment(subscribersCutoff.date).subtract(1, 'weeks').toDate();
      })
    : dataSum;

  // If there is only 1 data point, add in a "0 point" so that the line graph is actually a line
  const dataSelected =
    trimmedData.length === 1
      ? [
          {
            total: 0,
            hoverValue: 0,
            date: moment(trimmedData[0].date).subtract(1, 'day').toDate(),
          },
          trimmedData[0],
        ]
      : trimmedData;

  return dataSelected;
};

const generateConsecutiveDataPointsFromYears = (
  initial: number,
  quantity: number
) => {
  const years = createConsecutiveNumbers(initial, quantity);
  return years.map((year) => ({
    date: new Date(year, 0, 1),
    hoverValue: 0,
    total: 0,
  }));
};
const generateEquallySpacedDataPointsFromYears = (
  initial: number,
  maxQuantity: number,
  maxYear: number
) => {
  const denominator = wrap(() => {
    if (maxQuantity < 10) {
      return maxQuantity - 1;
    }

    switch (0) {
      case (maxYear - initial) % 6:
        return 6;
      case (maxYear - initial) % 5:
        return 5;

      case (maxYear - initial) % 4:
        return 4;
      case (maxYear - initial) % 3:
        return 3;
      default:
        return 4;
    }
  });

  const difference = Math.floor((maxYear - initial) / denominator);
  const years = createEquallySpacedNumbers(initial, denominator, difference);
  return [...years, maxYear].map((year) => ({
    date: new Date(year, 0, 1),
    hoverValue: 0,
    total: 0,
  }));
};

export class LineChart extends React.Component<Props> {
  static readonly defaultProps = {
    entityLabel: 'Subscribers',
  };

  render() {
    const {
      entityLabel,
      hideAxisLines,
      hideMonthTick,
      innerMargin,
      lineColor,
      times,
      transition,
      xMaxTicksQuantity = 5,
      yMinValue,
      yMaxValue,
      hideYTick,
      hideXTick,
      hasIndicators,
      maxXDataPoints,
    } = this.props;
    const isYearInterval = times?.interval === TimeBreakdownInterval.YEAR;
    let dataSelected = hasIndicators
      ? _.sortBy(
          (times?.data || []).map((d) => {
            return {
              date: d.time,
              hoverValue: d.value,
              total: d.value,
              formattedValue: d.formattedValue,
              additionalData: {
                indexScore: d.additionalData?.indexScore,
              },
            };
          }),
          ({ date }) => date
        )
      : getSubscribersData(times);

    const showNullState = !times?.data || !times.data.length;

    // creates an array of empty years to draw an empty chart
    if (showNullState) {
      dataSelected = generateConsecutiveDataPointsFromYears(
        new Date().getFullYear() - xMaxTicksQuantity,
        xMaxTicksQuantity
      );
    }

    const getTooltipContentSubscribers = (dataPoint: DataPoint) => {
      return `
        <span class="bold">${d3.timeFormat('%b %d, %Y')(dataPoint.date)}</span>
        <span>${dataPoint.hoverValue} New ${entityLabel}</span>
        <span>${dataPoint.total} Total ${entityLabel}</span>
      `;
    };

    const getTooltipContentIndicators = (dataPoint: DataPoint) => {
      const buildIndexScorePart = (title: string): string => {
        return `
          <div class="d-flex justify-content-between font-size-sm w-100">
            <span>${title}</span> <span class="bold">${
          dataPoint.formattedValue || dataPoint.hoverValue
        }</span>
          </div>
          <div class="d-flex justify-content-between font-size-sm w-100 pt-1">
            <span>${
              isYearInterval ? 'Year' : 'Date'
            }</span> <span class="bold">${moment
          .utc(dataPoint.date)
          .format(isYearInterval ? YEAR_FORMAT : MMM_YEAR_FORMAT)}</span>
          </div>
        `;
      };
      return !times?.isIndicator
        ? buildIndexScorePart('Value')
        : `
          ${buildIndexScorePart('Value')}
          ${
            dataPoint.additionalData?.indexScore
              ? `<div class="d-flex justify-content-between font-size-base w-100 border-top pt-1 mt-2">
                <span>Index score</span> <span class="bold">${
                  dataPoint.additionalData?.indexScore
                    ? dataPoint.additionalData?.indexScore.toFixed()
                    : 'N/A'
                }</span>
              </div>`
              : ''
          }
        `;
    };

    const getTooltipContentSize = (dataPoint: DataPoint) => {
      return !times?.isIndicator || !dataPoint.additionalData?.indexScore
        ? SMALL_TOOLTIP_SIZES
        : BIG_TOOLTIP_SIZES;
    };

    // builds the custom tick values for YEAR and MONTH
    const buildXTickValues = () => {
      if (!hasIndicators) {
        return;
      }

      if (isYearInterval) {
        const minYear = dataSelected[0].date.getFullYear();
        const maxYear = dataSelected[dataSelected.length - 1].date.getFullYear();
        const dataPoints =
          _.isUndefined(maxXDataPoints) || maxYear - minYear <= maxXDataPoints
            ? generateConsecutiveDataPointsFromYears(
                minYear - 1,
                maxYear - minYear + 1
              )
            : generateEquallySpacedDataPointsFromYears(
                minYear,
                Math.min(maxYear - minYear + 1, maxXDataPoints),
                maxYear
              );

        return dataPoints.map(({ date }) => date);
      } else {
        const startDate = moment.utc(dataSelected[0].date);
        const endDate = moment.utc(dataSelected[dataSelected.length - 1].date);
        const daySteps = Math.ceil(
          endDate.diff(startDate, 'days') /
            ((maxXDataPoints ?? xMaxTicksQuantity) - 1)
        );

        const calculatedTicks = [startDate.toDate()];
        for (let i = 1; i < (maxXDataPoints ?? xMaxTicksQuantity) - 1; i++) {
          const initialDate = startDate.clone();
          calculatedTicks.push(initialDate.add(daySteps * i, 'days').toDate());
        }
        calculatedTicks.push(endDate.toDate());

        return calculatedTicks.map((date) => date);
      }
    };

    return (
      <NullOverlay enabled={showNullState}>
        <Chart {...this.props}>
          {(chart, chartWidth, chartHeight) => {
            const innerWidth = chartWidth - innerMargin.left - innerMargin.right;
            const innerHeight = chartHeight - innerMargin.top - innerMargin.bottom;

            const x = d3.scaleTime().range([0, innerWidth]);
            const y = d3.scaleLinear().range([innerHeight, 0]).nice();

            x.domain(
              d3.extent<DataPoint, Date>(dataSelected, (d) => {
                return d.date;
              }) as [Date, Date]
            );

            y.domain([
              showNullState ? 0 : yMinValue ? yMinValue : 0,
              showNullState
                ? 100
                : yMaxValue
                ? yMaxValue
                : d3.max(dataSelected, (d) => d.total) || 0,
            ]);

            return (
              <>
                <Axis
                  axis={y}
                  axisLocation={AxisLocation.LEFT}
                  chart={chart}
                  className={'line-chart-y'}
                  hideAxisLine={!!hideAxisLines}
                  tickNumber={5}
                  tickSize={!!hideYTick ? 0 : -innerWidth}
                  translateX={innerMargin.left}
                  translateY={innerMargin.top}
                  type={PropsTypes.LINEAR}
                  width={innerWidth}
                />
                <Axis
                  axis={x}
                  axisLocation={AxisLocation.BOTTOM}
                  chart={chart}
                  className={'line-chart-x'}
                  hideAxisLine={!!hideAxisLines}
                  hideMonthTick={!!hideMonthTick}
                  tickSize={!!hideXTick ? 0 : -innerHeight}
                  tickValues={buildXTickValues()}
                  translateX={innerMargin.left}
                  translateY={chartHeight - innerMargin.bottom}
                  type={PropsTypes.TIME}
                  width={innerWidth}
                />
                {!showNullState && (
                  <>
                    <Line
                      chart={chart}
                      data={dataSelected}
                      lineColor={lineColor}
                      transition={transition}
                      translateX={innerMargin.left}
                      translateY={innerMargin.top}
                      x={x}
                      y={y}
                    />
                    <Hover
                      chart={chart}
                      data={dataSelected}
                      getDinamicSize={getTooltipContentSize}
                      height={innerHeight}
                      onTooltipHover={
                        hasIndicators
                          ? getTooltipContentIndicators
                          : getTooltipContentSubscribers
                      }
                      tooltipSizes={
                        hasIndicators
                          ? !times?.isIndicator
                            ? SMALL_TOOLTIP_SIZES
                            : BIG_TOOLTIP_SIZES
                          : undefined
                      }
                      translateX={innerMargin.left}
                      translateY={innerMargin.top}
                      uniqueId={this.props.uniqueId ? this.props.uniqueId : ''}
                      width={innerWidth}
                      x={x}
                      y={y}
                    />
                  </>
                )}
              </>
            );
          }}
        </Chart>
      </NullOverlay>
    );
  }
}
