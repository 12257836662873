import { extractGqlValue } from 'core';
import _ from 'lodash';
import * as React from 'react';
import { useQueryInfo } from '../containers/query';
import * as QueryInfos from '../graphql-queries/query-infos';

export interface FlagsContextInterface {
  /**
   * Check if a flag is enabled.
   * @param flagKey
   * @returns A boolean indicating if the provided flag is
   *  enabled.
   * @note Returns false when flags are not yet loaded, or if
   *  the requested flag encountered some error (such as it
   *  not existing)
   */
  readonly isEnabled: (flagKey: string) => boolean;
  readonly isLoading: boolean;
}

export interface FlagsContextProviderProps {
  readonly children?: React.ReactNode;
}
/**
 * Update this enum to include any flags used in the application. Delete them
 * when the flag is cleaned up and removed.
 *
 * @note Typing breaks further down if this enum is empty, so in the event
 * that you are removing the last flag in use in the application, just add
 * some obviously bogus entry here.
 */
export enum KnownFlag {
  FAKE = 'fake-flag',
  AI_ASSISTANT = 'ai-assistant',
  GRANT_WRITER = 'grant-writer',
  CONTENT_POST_QUESTIONS = 'content-post-questions',
  ENGAGE_EMAIL_LIST = 'engage-email-list',
  RESIDENTS_STORE_LOCATION = 'residents-store-location',
  TRACK_OVERVIEW_FREE = 'track-overview-free',
  IGNORE_FEATURE_SETTINGS = 'ignore-feature-settings',
  AI_ASSISTANT_QUERY_LIMIT = 'ai-assistant-query-limit',
  IN_APP_PURCHASES = 'in-app-purchases',
  DEFAULT_FREE_POLLY_LIMIT = 'default-free-polly-limit',
  FREE_TRIAL_ON_REGISTRATION = 'free-trial-on-registration',
  SELF_SERVICE_BUDGET_SIM = 'self-service-budget-sim',
  DEMO_BENCHMARK_REPORTS = 'demo-benchmark-reports',
}

/**
 * Context for interacting with feature flags throughout the application.
 */
export const FlagsContext = React.createContext<FlagsContextInterface>({
  isEnabled: () => false,
  isLoading: false,
});
FlagsContext.displayName = 'FlagsContext';

/**
 * Component that fetches and sets up the data for a {@link FlagsContext} and provides
 * it to children.
 */
export const FlagsContextProvider: React.FC<FlagsContextProviderProps> = ({
  children,
}) => {
  const currentUser = useQueryInfo(QueryInfos.currentUser, {});
  const privateAdmin = extractGqlValue(
    currentUser.data?.currentUser.user?.admin,
    'PrivateAdmin'
  );
  const user = extractGqlValue(currentUser.data?.currentUser.user, 'User');
  const userId = user?.id;
  const publishingEntityId = privateAdmin?.activePublishingEntity?.id;

  const { data, error, loading } = useQueryInfo(QueryInfos.currentActiveFlags, {
    variables: {
      userId,
      publishingEntityId,
    },
    skip: currentUser.loading,
  });

  const isLoading = _.isUndefined(data) && (currentUser.loading || loading);

  React.useEffect(() => {
    if (error) {
      console.error('Error in flags context');
      console.error(error);
    }
  }, [error]);

  const isEnabled = React.useMemo<FlagsContextInterface['isEnabled']>(
    () => (flagKey: string) => {
      // scan the flags array for the requested flag
      const flag = data?.flags.flags.find((curFlag) => curFlag.flagKey === flagKey);
      if (!flag || flag.__typename === 'ErrorFlag') {
        // if the flag doesn't exist or fails to load, we it's considered off
        return false;
      }
      switch (flag.__typename) {
        case 'BooleanFlag':
          // boolean flags are strictly on or off
          return flag.enabled;
        case 'VariantFlag':
          // handling variant flags here is useful for cases where some
          // logic is shared between multiple variant values for a flag
          return flag.match;
        default:
          console.error(
            `Unexpected flag response type encountered while testing flag ${flagKey}: "${
              (flag as any).__typename
            }"`
          );
          return false;
      }
    },
    [data]
  );

  // actual value used in the context
  const ctxValue: FlagsContextInterface = React.useMemo(
    () => ({ isEnabled, isLoading }),
    [isEnabled, isLoading]
  );

  return <FlagsContext.Provider value={ctxValue}>{children}</FlagsContext.Provider>;
};

const useFlagContext = () => React.useContext(FlagsContext);

/**
 * Hook to check if a particular flag is enabled.
 * @param flagKey
 * @returns A boolean indicating if the provided flag is enabled.
 * @note Returns false when flags are not yet loaded, or if
 *  the requested flag encountered some error (such as it
 *  not existing)
 */
export const useFlagEnabled = (flagKey: KnownFlag) =>
  useFlagContext().isEnabled(flagKey);

/**
 * Hook to check if a particular flag context is loading.
 * @returns A boolean indicating if the flag context is initialized.
 * @note Returns true when data is undefined and currentUser or currentActiveFlags
 *  is loading
 */
export const useFlagIsLoading = () => useFlagContext().isLoading;
